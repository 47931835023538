import { ready } from 'brei-project-utils';
import { animations } from './modules/animations';

// Example of importing a custom module
// import { primaryNav } from './modules/primaryNav';

const main = {

	elem: {
	},

	init() {

		animations.init();
		//this.bindUIActions();

	},

	bindUIActions() {

	}

};

ready(function () {
	main.init();
});
