const animations = {
	elem: {
		$items: document.querySelectorAll('[data-animate]'),
	},

	init() {

		if (!this.elem.$items.length) {
			// return;
		}

		const lSections = [...this.elem.$items];
		
		$('.masthead').addClass('animate-me');
		$('.masthead__content').addClass('animate-me');

		const lObserver = new IntersectionObserver(
			(pItems) => {
				pItems.forEach((pItem, index) => {
					let lDelay = 250 * (index + 1);
					// let lDelay = 200 * Math.floor((Math.random() + 1) * pItems.length);
					// console.log('lDelay =', lDelay)
					pItem.target.style.transitionDelay = `${lDelay}ms`;
					pItem.target.classList.toggle(
						'animate-me',
						pItem.isIntersecting
					);

					if (pItem.isIntersecting) {
						lObserver.unobserve(pItem.target);
					}

				});
			},
			{ threshold: 0 }
		);

		lSections.forEach((pItem) => {
			lObserver.observe(pItem);
		});
	},
};

export { animations };
